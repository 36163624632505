import { createStore } from '@stencil/store';

interface State {
  currentView: string;
  repliesData: any;
  messagesData: any;
  channelData: any;
  workspaceData: any;
  senderData: any;
  activeChatId: any;
  isPopupOpen: boolean;
  isLoading: boolean;
  settings: any;
  reachedEndOfContent: boolean;
  isloadingMessage: boolean;
  searchData: string;
  unreadNotificationCount?: number | null;
  userListData: Array<any>;
  attachmentToolTip: boolean;
  reachedEndOfContentReplies: boolean;
}

const { state } = createStore<State>({
  currentView: 'view-conversation',
  repliesData: [],
  messagesData: [],
  channelData: {},
  workspaceData: {},
  senderData: {},
  activeChatId: null,
  isPopupOpen: false,
  isLoading: true,
  settings: {},
  reachedEndOfContent: false,
  isloadingMessage: false,
  searchData: '',
  unreadNotificationCount: 0,
  userListData: [],
  attachmentToolTip: false,
  reachedEndOfContentReplies: true,
});

export default state;
